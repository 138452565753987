import { render, staticRenderFns } from "./Spwaiting.vue?vue&type=template&id=de022b78&scoped=true&lang=pug&"
import script from "./Spwaiting.vue?vue&type=script&lang=ts&"
export * from "./Spwaiting.vue?vue&type=script&lang=ts&"
import style0 from "./Spwaiting.vue?vue&type=style&index=0&id=de022b78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de022b78",
  null
  
)

export default component.exports